import { createRouter, createWebHistory } from 'vue-router'
import PostComponent from "../components/posts/PostComponent.vue";
import FormComponent from "../components/posts/FormComponent.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: PostComponent
    },
    {
        path: '/admin/add/form',
        name: 'form-admin',
        component: FormComponent
    }
]

const router = createRouter({ history: createWebHistory(), routes })
export default router