<template>
  <div>
    <div class="posts">
        <form ref="formzz" >
      <div class="post">
        <div class="form-control">
          <input
            type="text"
            v-model="dataObj.number"
            placeholder="Plate Number"
            name=""
            id=""
          />
        </div>
        <div class="form-control">
          <input
            type="text"
            v-model="dataObj.name"
            placeholder="Name"
            name=""
            id=""
          />
        </div>
        <div class="form-control">
          <input
            type="text"
            v-model="dataObj.department"
            placeholder="Department"
            name=""
            id=""
          />
        </div>
        <div class="form-control">
          <input
            type="text"
            v-model="dataObj.contact"
            placeholder="Contact Number"
            name=""
            id=""
          />
        </div>
        <div class="form-control">
          <button type="button"  @click="newData">Submit</button>
        </div>
      </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
export default {
  name: "FormComponent",
  data() {
    return {
      dataObj: {},
    };
  },

  methods: {
     newData() {
       
       // e.preventDefault();
      if (this.dataObj.number) {
        this.dataObj.numberz = this.dataObj.number.toUpperCase();
      }
     
     axios.post(
        "/api/plates/create-new/data",
        this.dataObj
      ).then((response)=>{
        
        if(response && response.data.acknowledged){
            this.dataObj = {};
            this.$refs.formzz.reset();
        }
            
      });
    },
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.posts {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0;
  border: 1px solid #ccc;
}
.form-control {
  padding: 5px;
}
.form-control input {
  padding: 5px;
}
</style>
  