<template>
  <div>
    <div class="posts">
      <div class="post">
        <input
          type="text"
          name="plateNumber"
          v-model="platenumber"
          id="plateNumber"
           autofocus   
          @keyup.enter="getPlateNumber"
        />
        <input type="button" @click="getPlateNumber" value="Search" />
      </div>
    </div>
    <div class="posts" style="max-width:600px; margin:20px auto;">
        <div class="post d-flex">
        <div class="item">
         PLATE NUMBER
        </div>
        <div class="item">
          NAME
        </div>
        <div class="item">
          DEPARTMENT
        </div>
         <div class="item">
          CONTACT
        </div>
      </div>
      <div class="post d-flex">
        <div class="item">
          {{ posts.number }}
        </div>
        <div class="item">
          {{ posts.name }}
        </div>
        <div class="item">
          {{ posts.department }}
        </div>
         <div class="item">
          {{ posts.contact }}
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import PostService from '@/services/PostServices';
import axios from "axios";
export default {
  name: "PostComponent",
  data() {
    return {
      posts: [],
      platenumber: "",
      error: "",
      text: "",
    };
  },

  methods: {
    async getPlateNumber() {
      let plateNumber = this.platenumber.toUpperCase();

      const response = await axios.get(
        "api/plates/" + plateNumber
      );
    
      this.posts = response.data;
      if(this.posts){
        setTimeout(() => {
          this.platenumber = '';
        }, 500);
      }
    },
  },
   
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-flex{
    display:flex;
    margin:0 auto;
}
.post .item{ width: calc(330% - 30px); border: 1px solid #ccc; font-weight: bold; padding:10px 0;}
</style>
  